import {
  ENVIRONMENT_INITIALIZER,
  InjectionToken,
  Provider,
  inject
} from '@angular/core';
import { Icon } from './generated-icons-barrel';
import { IconRegistry } from './icon-registry';
import { IconsConfig } from './types';

export const KNK_ICONS_CONFIG = new InjectionToken<IconsConfig>(
  'KNK_ICONS_CONFIG',
  {
    providedIn: 'root',
    factory() {
      return {} as IconsConfig;
    }
  }
);

export function provideIcons(icons: Icon | Icon[]): Provider {
  return {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue: () => {
      inject(IconRegistry).register(icons);
    }
  };
}

export function provideIconsConfig(config: Partial<IconsConfig>) {
  return {
    provide: KNK_ICONS_CONFIG,
    useValue: config
  };
}
