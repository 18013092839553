import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  inject
} from '@angular/core';
import { createContextLogger } from '@konnektu/helpers';
import { IconName } from './generated-icons-barrel';
import { IconRegistry } from './icon-registry';
import { KNK_ICONS_CONFIG } from './providers';
import { IconSizes, IconsConfig } from './types';

/**
 * Не использовать этот компонент, так как он есть в 3 раза больше памяти чем библиотека которая расширяет набор иконок тайги.
 *
 * См. библиотеку `konnektu-taiga`
 * @deprecated
 */
@Component({
  selector: 'knk-icon',
  template: '',
  standalone: true,
  styles: [
    `
      :host {
        display: inline-block;
        fill: currentColor;
        width: var(--svg-icon-width, 1em);
        height: var(--svg-icon-height, 1em);
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges {
  private readonly host = inject(ElementRef);

  private readonly registry = inject(IconRegistry);

  private readonly config = inject(KNK_ICONS_CONFIG);

  private readonly logger = createContextLogger('IconComponent');

  @HostBinding('attr.role') role = 'img';

  @HostBinding('attr.aria-hidden') ariaHidden = 'true';

  @Input() key!: IconName;

  @Input() fallback!: IconName;

  @Input() size!: keyof IconSizes;

  @Input() width!: number | string;

  @Input() height!: number | string;

  @Input() fontSize!: number | string;

  @Input() color!: string;

  @Input() noShrink = false;

  @Input() preserveAspectRatio: string | undefined;

  private mergedConfig: IconsConfig;

  private lastKey!: string;

  private init = false;

  constructor() {
    this.mergedConfig = this.createConfig();
  }

  get element(): HTMLElement {
    return this.host.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['key']) {
      this.setIcon(this.key);
    }

    if (changes['size']?.currentValue) {
      this.setIconSize(this.mergedConfig.sizes[this.size]);
    }

    if (changes['fontSize']?.currentValue) {
      this.setIconSize(coerceCssPixelValue(this.fontSize));
    }

    // If on the first change no size was passed, set the default size
    if (
      !this.init &&
      !changes['size']?.currentValue &&
      !changes['fontSize']?.currentValue
    ) {
      this.setIconSize(
        this.mergedConfig.sizes[this.mergedConfig.defaultSize || 'md']
      );
    }

    if (changes['width']) {
      this.element.style.width = `var(--svg-icon-width, ${coerceCssPixelValue(
        this.width
      )})`;
    }

    if (changes['height']) {
      this.element.style.height = `var(--svg-icon-height, ${coerceCssPixelValue(
        this.height
      )})`;
    }

    if (changes['color']) {
      this.element.style.color = `var(--svg-icon-color, ${this.color})`;
    }

    this.init = true;
  }

  private createConfig() {
    const defaults: IconsConfig = {
      sizes: {
        xs: '0.5rem',
        sm: '0.75rem',
        md: `1rem`,
        lg: '1.5rem',
        xl: '2rem',
        xxl: '2.5rem'
      }
    };

    const mergedConfig = {
      ...defaults,
      ...this.config
    };

    mergedConfig.sizes = Object.entries({
      ...defaults.sizes,
      ...mergedConfig.sizes
    }).reduce((acc, [key, value]) => {
      acc[key] = `var(--svg-icon-font-size-${key}, ${value})`;

      return acc;
    }, {} as IconSizes);

    return mergedConfig;
  }

  private setIconSize(size: string) {
    this.element.style.fontSize = size;
    if (this.noShrink) {
      this.element.style.minWidth = size;
    }
  }

  private setIcon(name: string) {
    const config = { preserveAspectRatio: this.preserveAspectRatio };
    const icon =
      this.registry.get(name, config) ??
      this.registry.get(
        this.fallback ?? this.config.missingIconFallback?.name,
        config
      );

    if (icon) {
      this.element.setAttribute('aria-label', `${name}-icon`);
      this.element.classList.remove(getIconClassName(this.lastKey));
      this.lastKey = name;
      this.element.classList.add(getIconClassName(name));
      this.element.innerHTML = icon;
    } else {
      this.logger.warning(`Icon ${name} not found in injector`);
    }
  }
}

function coerceCssPixelValue(value: any): string {
  if (value == null) {
    return '';
  }

  return typeof value === 'string' ? value : `${value}px`;
}

function getIconClassName(key: string) {
  return `svg-icon-${key}`;
}
