import { DOCUMENT } from '@angular/common';
import { forwardRef, inject, Injectable } from '@angular/core';
import { Icon } from './generated-icons-barrel';
import { KNK_ICONS_CONFIG } from './providers';
import { IconsConfig } from './types';

class SvgIcon {
  init = false;

  constructor(public content: string) {}
}

@Injectable({ providedIn: 'root' })
export class IconRegistry {
  private svgMap = new Map<string, SvgIcon>();

  private document = inject(DOCUMENT);

  private readonly config = inject<IconsConfig>(
    forwardRef(() => KNK_ICONS_CONFIG)
  );

  constructor() {
    if (this.config?.icons) {
      this.register(this.config.icons);
    }

    if (this.config?.missingIconFallback) {
      this.register(this.config.missingIconFallback);
    }
  }

  getAll() {
    return this.svgMap;
  }

  get(
    key: string | undefined,
    config: { preserveAspectRatio?: string; asDataUrl?: boolean } = {}
  ): string | undefined {
    const icon = key && this.svgMap.get(key);

    if (!icon) {
      return undefined;
    }

    if (!icon.init) {
      const svg = this.toElement(icon.content);
      svg.setAttribute('fit', '');
      svg.setAttribute('height', '100%');
      svg.setAttribute('width', '100%');
      svg.setAttribute(
        'preserveAspectRatio',
        config.preserveAspectRatio ?? 'xMidYMid meet'
      );
      svg.setAttribute('focusable', 'false');

      icon.content = svg.outerHTML;
      icon.init = true;
    }

    if (config.asDataUrl) {
      const svg = this.toElement(icon.content).outerHTML;

      return `data:image/svg+xml;base64,${btoa(svg)}`;
    }

    return icon.content;
  }

  register(icons: Icon | Icon[]) {
    for (const { name, data } of Array.isArray(icons) ? icons : [icons]) {
      if (!this.svgMap.has(name)) {
        this.svgMap.set(name, new SvgIcon(data));
      }
    }
  }

  getSvgElement(name: string): SVGSVGElement | undefined {
    const content = this.get(name);

    if (!content) {
      return undefined;
    }

    const div = this.document.createElement('div');
    div.innerHTML = content;

    return div.querySelector('svg') as SVGSVGElement;
  }

  private toElement(content: string): SVGElement {
    const div = this.document.createElement('div');
    div.innerHTML = content;

    return div.querySelector('svg') as SVGElement;
  }
}
